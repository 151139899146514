import React from 'react';
import styled from 'styled-components';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import qs from 'query-string';
import * as Ant from 'antd';
import {useOutlet} from 'reconnect.js';

function Eng(props) {
  const [article, setArticle] = React.useState(null);
  const [carouselIdx, setCarouselIdx] = React.useState(0);
  const carouselRef = React.useRef();
  const {id} = qs.parse(props.location.search);
  const [landing] = useOutlet('landing');
  const {custom_layout} = landing || {};

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      if (id) {
        try {
          setArticle(
            await JStorage.fetchOneDocument('Article_Default', {id}, null, {
              token: null,
            }),
          );
        } catch (ex) {
          console.warn('REV_DBG', ex);
        }
      } else {
        // in english landing
        // set_custom_layout({});
      }
      AppActions.setLoading(false);
    }

    fetchData();
  }, [id]);

  if (id) {
    return (
      <LandingWrapper>
        {article && (
          <div className="content">
            <h1>{article.title}</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: article.raw_html?.enabled
                  ? article.raw_html.html
                  : article.html,
              }}
            />
          </div>
        )}
      </LandingWrapper>
    );
  }

  const carousel = [{image: custom_layout?.eng_landing_banner}];

  return (
    <LandingWrapper>
      <Ant.Carousel
        dots={false}
        draggable={true}
        ref={carouselRef}
        afterChange={(idx) => {
          setCarouselIdx(idx);
        }}>
        {carousel.map((it, idx) => {
          return (
            <div key={idx}>
              <div className="revtel-carousel-item">
                {it.image && <img src={it.image} alt="carousel-item" />}
              </div>
            </div>
          );
        })}
      </Ant.Carousel>

      <div className="revtel-carousel-dots">
        {carousel.map((_, idx) => {
          const selected = idx === carouselIdx;
          return (
            <div
              key={idx}
              style={{backgroundColor: selected ? 'white' : '#ccc'}}
              onClick={() => {
                if (carouselRef.current) {
                  carouselRef.current.goTo(idx);
                }
              }}
            />
          );
        })}
      </div>

      <div className="content">
        <h2>About TWPAA</h2>
        <div>
          Although the Patent Attorney Act was submitted to the Legislative Yuan
          for deliberation in 1988, no further progress was made for almost 20
          years. Due to relentless efforts and discussions undertaken by senior
          members of the APAA, as well as coordination by then Director General
          of the TIPO, Mr. Cai Liansheng, the Patent Attorney Act was finally
          promulgated on July 11th, 2007 and went into effect on January 11th,
          2008. The first Taiwan Patent Attorney Bar Examination was held in
          August 2008, with the first group of patent attorneys becoming
          registered in May 2009 after passing the bar examination and receiving
          training. About 70 Patent Agents who were exempt from the bar
          examination obtained patent attorney qualification after receiving
          professional training. A promotional meeting of patent attorneys was
          held on July 29th, 2009 by the TIPO in accordance with the Patent
          Attorney Act, and a formal application for establishing the
          association was filed with the Ministry of the Interior on August 14th
          of the same year. The much-anticipated “Taiwan Patent Attorney
          Association” (TWPAA) was finally established on December 11th, 2009.
        </div>
      </div>
    </LandingWrapper>
  );
}

const LandingWrapper = styled.div`
  .revtel-carousel-item {
    margin: 0;
    height: 480px;
    background: #ccc;
    position: relative;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .revtel-carousel-dots {
    position: relative;
    margin-top: -30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    & > * {
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background-color: pink;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.5);
    }
  }

  .content {
    max-width: 900px;
    margin: 20px auto;
    margin-top: 40px;
    padding: 20px;
  }
`;

export default withPageEntry(Eng);
